<template>
<p class="tourial-footer" v-if="['/explore/wizard', '/explore/login', '/explore/register', '/explore/welcome'].includes($route.path)">
    <a @click="showPrivacyPolicy">{{ $t('routes.Legal - Privacy Policy') }}</a>
    <span>|</span>
    <a @click="showTos">{{ $t('routes.Legal - Tos') }}</a>
</p>
</template>

<script>
export default {
    name: 'footer',
    methods: {
        showTos() {
            this.$router.push('/tos');
        },

        showPrivacyPolicy() {
            this.$router.push('/privacy-policy');
        },
    }
}
</script>

<style>
.tourial-footer {
    z-index: 1000;
    color: var(--primary);
    display: flex;
    align-items: center;
    gap: 10px;
    opacity: 0.5;
    position: absolute;
    bottom: 0px;
    right: 20px;
}

.tourial-footer a {
    cursor: pointer;
    font-size: 12px;
}

/* MOBILE */
@media (max-width: 600px) {
    .tourial-footer {
        width: 100%;
        justify-content: center;
        flex-direction: column;
        position: relative;
        right: unset;
        bottom: unset;
    }

    .tourial-footer span {
        display: none;
    }
}
</style>
